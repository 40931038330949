/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "./hooks/useSiteMetadata";

interface Props {
  title: string;
  description?: string;
  lang?: string;
  meta?: (
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined })[];
}

function SEO({ title, description = "", lang = "en", meta = [] }: Props) {
  const {
    title: metaTitle,
    description: metaDescrip,
    author,
  } = useSiteMetadata();

  const metaDescription = description || metaDescrip;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${metaTitle}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

export default SEO;
